import Glide from '@glidejs/glide';

const b2bBlogCardsSelector = '.blog-cards__carousel.glide';
if (document.querySelector(b2bBlogCardsSelector)){
  const b2bBlogCardsSlider = new Glide(b2bBlogCardsSelector, {
    type: 'slider',
    startAt: 0,
    perView: 3,
    gap: 20,
    breakpoints: {
      1024: {
        perView: 2,
        gap: 20,
        peek: {
          before: 40,
          after: 40,
        },
      },
      650: {
        perView: 1,
        gap: 10,
        peek: {
          before: 32,
          after: 32,
        },
      },
    },
  });
  b2bBlogCardsSlider.on('mount.after', () => {
    window.dispatchEvent(new Event('resize'));
  });
  b2bBlogCardsSlider.mount();
}