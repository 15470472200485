import Glide from "@glidejs/glide";
import Sizes from "../shared/glide_fixed_width_slides";

const b2bIndexSliderSelector = '.b2b_new-hero__carousel.glide';
if (document.querySelector(b2bIndexSliderSelector)) {
    const config = {
        type: 'carousel',
        gap: 40,
        slideWidth: 358,

        perView: 4,
        startAt: 0,


        breakpoints: {
            768: {
                perView: 1,
                startAt: 0,
                peek: {
                    before: 0
                },
            },
            1556: {
                perView: 1,
                startAt: 1,
                peek: {
                    before: -50
                },
            }
        }


    };

    const b2bIndexSlider = new Glide(b2bIndexSliderSelector, config);
    b2bIndexSlider.on('mount.after', () => {
        document.querySelector(".glide__slides.carousel-slides").classList.remove("carousel-slides")
    });
    b2bIndexSlider.mount({
        Sizes
    });
}

const b2bIndexSliderSelector2 = '.b2b_new-platform__grid-mobile.glide';
if (document.querySelector(b2bIndexSliderSelector2)) {
    const config = {
        type: 'carousel',
        gap: 20,
        slideWidth: 300,

        perView: 1,
        startAt: 0,
        peek: {
            before: 0
        },

    };

    const b2bIndexSlider2 = new Glide(b2bIndexSliderSelector2, config);
    b2bIndexSlider2.mount({
        Sizes
    });
}

const b2bIndexQuoteSliderSelector = '.b2b_new-quote__carousel .glide';
if (document.querySelector(b2bIndexQuoteSliderSelector)) {
    const config = {
        type: 'carousel',
        slideWidth: 1100,
        perView: 1,
        startAt: 0,
        breakpoints: {
            1110: {
                slideWidth: 390,
                perView: 1,
                startAt: 0,
            }
        }

    };

    const b2bIndexQuoteSlider = new Glide(b2bIndexQuoteSliderSelector, config);
    b2bIndexQuoteSlider.mount({
        Sizes
    });
}

// set up lottie animation behavior
const desktopGrid = document.querySelector('.b2b_new-platform__grid-desktop');
if (desktopGrid) {
    desktopGrid.querySelectorAll('.b2b_new-platform__highlight').forEach((highlight) => {
        highlight.addEventListener('mouseenter', () => {
            highlight.querySelector('lottie-player').setDirection(1);
            highlight.querySelector('lottie-player').setSpeed(0.5);
            highlight.querySelector('lottie-player').play();
        });
        highlight.addEventListener('mouseleave', () => {
            highlight.querySelector('lottie-player').setDirection(-1);
            highlight.querySelector('lottie-player').setSpeed(1);
            highlight.querySelector('lottie-player').play();
        });
    });
}

const playHighlightAnimation = (entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.querySelector('lottie-player').setDirection(1);
            entry.target.querySelector('lottie-player').setSpeed(0.5);
            entry.target.querySelector('lottie-player').play();
        } else {
            entry.target.querySelector('lottie-player').setDirection(-1);
            entry.target.querySelector('lottie-player').setSpeed(1);
            entry.target.querySelector('lottie-player').play();
        }
    });
};

const mobileGrid = document.querySelector('.b2b_new-platform__grid-mobile');
if (mobileGrid) {
    mobileGrid.querySelectorAll('.b2b_new-platform__highlight').forEach((highlight) => {
        const observer = new IntersectionObserver(
            (entries) => playHighlightAnimation(entries),
            {
                threshold: 1.0,
                rootMargin: '0%'
            }
        );
        observer.observe(highlight)
    });
}
