
import Glide from '@glidejs/glide';

const options = {
  type: 'slider',
  startAt: 0,
  perView: 4,
  gap: 24,
  breakpoints: {
    1024: {
      perView: 2,
      gap: 20,
      peek: {
        before: 40,
        after: 40,
      },
    },
    650: {
      perView: 1,
      gap: 10,
      peek: {
        before: 32,
        after: 32,
      },
    },
  },
};
document.querySelectorAll('.directory-featured-articles.glide')
    .forEach(el => {
      const highlightedPostsGlider = new Glide(el, options);
      highlightedPostsGlider.mount();
    });
