import Glide from '@glidejs/glide';
import '~/components/shared/globalFooter';
import '~/components/b2b/blogCardsSlider';
import '~/components/b2b/b2bIndexSliders';
import '@/components/b2b/b2bPhoneSlider';
import '~/components/b2b/articleCard';
import '~/components/b2b/caseStudyPage';
import '~/components/b2b/tooltip';

const dynamicImportTree = {
    'b2b-demos-show': () => {
        import('~/components/b2b/demo/demoForm');
    },
    'b2b-roi_calculator-index': () => {
        import('~/components/b2b/roi');
    },
    'b2b-reviews-index': () => {
        import('~/components/b2b/reviewsLandingPage');
    },
    'b2b-blog-index': () => {
        import('~/components/consumer/modules/blog-index');
    },
    'b2b-for_providers-pricing': () => {
        import('~/components/b2b/pricing');
    },
}

const currentPageId = document.body.id;
if (typeof dynamicImportTree[currentPageId] === 'function') {
    dynamicImportTree[currentPageId]();
}

const pagesWithReviewSlider = [
    "b2b-for_providers-acquire",
    "b2b-for_providers-streamline",
    "b2b-for_providers-urgent_cares",
    "b2b-for_providers-health_systems",
    "b2b-for_providers-why_solv",
    "b2b-for_providers-ai",
    "b2b-for_providers-solv_connect",
    "b2b-for_providers-solv_experience"
]

if (pagesWithReviewSlider.includes(currentPageId)) {
    // review carousels
    const b2bReviewsSelector = '.solv-reviews__carousel.glide';
    if (document.querySelector(b2bReviewsSelector)) {
        const b2bReviewsSlider = new Glide(b2bReviewsSelector, {
            type: 'carousel',
            perView: 1,
        });
        b2bReviewsSlider.on('mount.after', () => {
            window.dispatchEvent(new Event('resize'));
        });
        b2bReviewsSlider.mount();
    }
}

