const tooltips = document.querySelectorAll('[data-b2b-tooltip]');
tooltips.forEach(tooltip => {
  const id = tooltip.dataset.tooltipId;
  const activator = document.querySelector(`[data-tooltip-activator-id=${id}]`);
  if (activator) {
    ['mouseover', 'click', 'touch'].forEach(event => {
      activator.addEventListener(event, () => {
        // by setting the position by the position of the element, we don't need a wrapper, which allows us to avoid adding extra elements to the places with images
        const position = activator.getBoundingClientRect();
        tooltip.style.x = position.y + 40 + 'px';
        tooltip.style.y = position.x + 10 + 'px';
        tooltip.classList.add('show');
      });
    });

    activator.addEventListener('mouseleave', () => {
      tooltip.classList.remove('show');
    });

    window.addEventListener('click', (e) => {
      if (tooltip.classList.contains('show')
        && e.target !== tooltip
        && !tooltip.contains(e.target)
        && e.target !== activator
        && !activator.contains(e.target)
      ) {
        tooltip.classList.remove('show');
      }
    });
  }
});
